import React, { Component } from 'react'
import { MDBRow, MDBCol, MDBBtn, MDBCollapse, MDBContainer } from 'mdbreact'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'

export default class CollapsePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseID: '',
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }))
  }

  render() {
    const categories = this.props.data
    return (
      <section className="bg-light-blue py-5 rounded-bottom">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg="12">   
            {categories.map(({ node }, index) =>          
                <div key={index}>
                <MDBRow className="ml-xl-4 ml-2 mb-5">
                  <MDBCol lg="10">
                  <h4 className="font-alt font-w-700 letter-spacing-1 mb-3 text-xs-large text-large">
                    {node.frontmatter.categoryname}
                  </h4>
                  <p className="mb-0 text-medium">{node.frontmatter.categorydescription}</p>
                  </MDBCol>
                  <MDBCol lg="2" bottom className="mt-3">
                  <MDBBtn
                        onClick={this.toggleCollapse(node.frontmatter.categoryname)}
                        className="btn btn-md btn-mdb-color"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                        View
                      </MDBBtn>
                  </MDBCol>
                  </MDBRow>

                  <MDBCollapse
                    id={node.frontmatter.categoryname}
                    isOpen={this.state.collapseID}
                  >
                  <MDBRow className="ml-xl-4 ml-sm-2 ml-2 mb-5">
                   {node.frontmatter.related && 
                    <>
                      {node.frontmatter.related.map((relates, index) => (
                          <MDBCol lg="4" md="6" key={index}>
                            <p className="text-medium">
                                <Link to={relates.link}  className="effect">{relates.name}</Link>
                              </p>
                          </MDBCol>
                        ))}
                    </>
                   } 
                  </MDBRow>
                  </MDBCollapse>
                </div>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        </section>
    )
  }
}
